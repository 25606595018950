<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            v-if="langInfo"
            :src="$i18n.locale == 'fa' ? fa_auth_banner : en_auth_banner"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-alert
            v-height-fade
            show
            dismissible
            fade
            class="mb-0"
            variant="success"
            v-if="sendEmail == true"
          >
            <div class="alert-body" v-if="sendEmail">
              <span>{{ $t("Pages.Auth.Title.success_recovery_pass") }}</span>
            </div>
          </b-alert>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 mt-1">
            {{ $t("Pages.Auth.Title.recovery_pass") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Pages.Auth.Title.email_forget_pass") }}
          </b-card-text>

          <!-- form -->
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group
              :label="$t('Pages.Auth.Label.email_address')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="userEmail"
                type="email"
                name="login-email"
                placeholder=""
                required
              />
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="sendEmailRecoveryPassword"
            >
              {{ $t("Pages.Auth.Button.send_btn") }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.already_have_account") }} </span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.Login") }} </span>
            </b-link>
          </p>

          <b-card-text class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.no_account") }} </span>
            <b-link :to="{ name: 'signup' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.create_account") }}</span>
            </b-link>
          </b-card-text>

          <b-card class="mb-0">
            <div class="auth-footer-btn d-flex justify-content-center">
              <div class="google-btn" v-on:click="loginGoogle()">
                <div class="google-icon-wrapper">
                  <img
                    class="google-icon"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  />
                </div>
                <p class="btn-text text-center w-100">
                  <b class="ml-1">{{ $t("Pages.Auth.Button.google_login") }}</b>
                </p>
              </div>
            </div>
          </b-card>

          <!-- <b-card-text class="mt-0 mb-0 text-center">
            <b-img
              :src="
                $store.state.appConfig.layout.skin == 'dark'
                  ? require('@/assets/images/banner/ytc-small-light.png')
                  : require('@/assets/images/banner/ytc-small.png')
              "
              alt="logo"
              height="80"
            />
          </b-card-text> -->
          <!-- /////change lang section -->
          <b-card-text class="mb-0 text-center">
            <Locale />
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { langInfoDao } from "@/daos/languages/langInfoDao";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    Locale,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: "",
      userEmail: "",
      sendEmail: false,
      langInfo: null,
      en_auth_banner: null,
      fa_auth_banner: null,
    };
  },
  async created() {
    this.getAllBanners();
  },
  methods: {
    sendEmailRecoveryPassword() {
      if (this.userEmail != "") {
        const payload = {
          email: this.userEmail,
        };
        this.$http
          .post(`/api/forgetPassword`, payload)
          .then((resp) => {
            this.sendEmail = true;
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Base.Alert.success_send_recovery_pass"),
              "success"
            );
          })
          .catch((error) => {
            if (error.response.status) {
              this.sendEmail = false;
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.not_found_recovery_pass"),
                "danger"
              );
              this.$VueCookies.remove("mastertube");
            }
          });
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.info_incomplete"),
          "danger"
        );
      }
    },
    loginGoogle() {
      this.makeToast(
        this.$t("Base.Alert.Successful"),
        this.$t("Base.Alert.please_wait"),
        "success"
      );
      this.$http
        .get("/api/login/google")
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => {});
          }
        });
    },
    async getAllBanners() {
      const langInfo = await langInfoDao.getLangInfo();
      this.langInfo = langInfo;
      const en_banners = langInfo.en.images;
      const fa_banners = langInfo.fa.images;
      this.en_auth_banner = en_banners.auth_banner;
      this.fa_auth_banner = fa_banners.auth_banner;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
